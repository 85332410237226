<template>
  <div class="table-wrapper block-el">
    <div class="row">
      <div class="col-md-6">
        <slot name="buttons" />
      </div>
      <div v-if="searchable" class="col-md-6">
        <form @submit.prevent="search">
          <div class="form-group">
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Search..."
                v-model="q"
              />
              <div class="input-group-append">
                <button class="btn btn-primary btn-icon" type="submit">
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table table-borderless">
            <thead>
              <tr>
                <th v-for="(column, n) in filteredColumns" :key="n">
                  {{ $tc(`app.${getColumnLabel(column)}`, 1) }}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody v-show="clickable" v-for="(item, n) in data" :key="n">
              <tr>
                <td :class="clickable ? 'clickable' : ''" @click="$emit('row-click', item)" v-for="(column, i) in filteredColumns" :key="i">
                  <span
                    v-html="getColumnValue(item, column)"
                    :class="getColumnClass(item, column)"
                  >
                  </span>
                </td>
                <td>
                  <slot name="actions" v-bind="item"></slot>
                </td>
              </tr>
              <tr v-if="!data.length">
                <td class="text-center" :colspan="columns.length">
                  {{ $tc('app.empty_table') }}
                </td>
              </tr>
            </tbody>
            <tbody v-if="!clickable">
              <tr v-for="(item, n) in data" :key="n">
                <td v-for="(column, i) in filteredColumns" :key="i">
                  <span
                    v-html="getColumnValue(item, column)"
                    :class="getColumnClass(item, column)"
                  >
                  </span>
                </td>
                <td>
                  <slot name="actions" v-bind="item"></slot>
                </td>
              </tr>
              <tr v-if="!data.length">
                <td class="text-center" :colspan="columns.length">
                  {{ $tc('app.empty_table') }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <pagination :lastPage="pages" @change="changePage" />
      </div>
    </div>
    <div v-if="loading" class="qt-block-ui"></div>
  </div>
</template>

<script>
import Vue from 'vue';
import Pagination from '@/components/Pagination.vue';

export default {
  name: 'DataTable',
  components: {
    Pagination,
  },
  props: {
    clickable: Boolean,
    searchable: Boolean,
    loading: Boolean,
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    pages: Number,
  },
  data() {
    return {
      q: '',
    };
  },
  computed: {
    filteredColumns() {
      const filteredColumns = [];

      this.columns.forEach((column) => {
        if (!column.hide) filteredColumns.push(column);
      });
      return filteredColumns;
    },
  },
  methods: {
    getColumnLabel(value) {
      if (value.label) return value.label;
      return this.getColumnKey(value);
    },
    getColumnKey(value) {
      return typeof value === 'string' ? value : value.key;
    },
    getColumnValue(item, column) {
      const keys = this.getColumnKey(column).split('.');

      for (let i = 0; i < keys.length; i += 1) {
        item = item[keys[i]];
        if (Array.isArray(item)) {
          item = item[0] ? item[0] : '';
        }
      }
      if (item === null) return '-';
      if (item === true) {
        return '<i class="fas fa-check color-green"></i>';
      }
      if (item === false) {
        return '-';
      }

      if (column.filters) {
        column.filters.forEach((filter) => {
          const filterParams = filter.split('|');
          item = Vue.filter(filterParams[0])(item, filterParams[1]);
        });
      }
      if (column.localize) {
        const loc = this.$tc(`app.${item}`, 1);

        if (loc.indexOf('app.') === -1) item = loc;
      }
      return item;
    },
    getColumnClass(item, column) {
      return typeof column.class === 'object'
        ? column.class[item[column.key]]
        : column.class;
    },
    changePage(page) {
      this.$emit('change-page', page);
    },
    search() {
      this.$emit('search', this.q);
    },
  },
};
</script>

<style scoped lang="scss">
  .clickable {
    cursor: pointer;
  }
</style>
